import React from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bidLogo from '../../assets/images/bid.png';
import './login.css';

axios.interceptors.response.use(
	function(response) {
		if (response.request.responseURL.includes('gaeco.mppi.mp.br')) {
			window.location.href = response.request.responseURL;
		}
		return response;
	}, function(error) {
		return Promise.reject(error);
	}
);

class Login extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			usuario: '',
			senha: '',
			disabled: false,
			error: false,
			errorMessage: ''
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleSubmit(event) {
		event.preventDefault();

		this.setState({disabled: true});

		axios.post('https://bidh.mppi.mp.br/WebService/search/security/login', {
				login: this.state.usuario,
				password: this.state.senha
			}, {'content-type': 'application/json', maxRedirects: 0})
			.then(response => {
				this.setState({disabled: false});
				const sessao = response.data.sessao[0];
				if (sessao) {
					localStorage.setItem('key', sessao.key);
					localStorage.setItem('uid', sessao.user_id);
					localStorage.setItem('user', sessao.user_name);
					localStorage.setItem('reports', '{}');
					window.location.href = window.location.pathname;
				} else {
					this.setState({error: true, errorMessage: 'Usuário ou Senha inválido(s).'});
				}
			})
			.catch(err => {
				this.setState({error: true, errorMessage: 'Erro no servidor de autenticação.', disabled: false});
			});
	}

	handleChange(event) {
		this.setState({[event.target.id]: event.target.value});
	}

	render() {
		return (
			<div className="login-container">
				<div className="login-content">
					<img className="logo" src={bidLogo} alt="Busca Integrada de Dados" />
					<p className="text-center">Busca Integrada de Dados</p>
					<p className="text-center app-name">Versão 101120240905</p>

					{
						this.state.error ?
							<div className="alert alert-danger">
								<FontAwesomeIcon icon="exclamation-circle" /> {this.state.errorMessage}
							</div> : ''
					}
					<form className="form-login align-middle" onSubmit={this.handleSubmit}>
						<div className="form-group">
							<label htmlFor="usuario" className="sr-only">Usuário</label>
							<div className="input-group input-group-usuario">
								<div className="input-group-prepend">
									<div className="input-group-text">
										<FontAwesomeIcon icon="user" />
									</div>
								</div>
								<input type="text" id="usuario" className="form-control" placeholder="Usuário" value={this.state.usuario} onChange={this.handleChange} autocomplete="off" required disabled={this.state.disabled} />
							</div>
						</div>
						<div className="form-group">
							<label htmlFor="senha" className="sr-only">Senha</label>
							<div className="input-group input-group-senha">
								<div className="input-group-prepend">
									<div className="input-group-text">
										<FontAwesomeIcon icon="lock" />
									</div>
								</div>
								<input type="password" id="senha" className="form-control" placeholder="Senha" value={this.state.senha} onChange={this.handleChange} autocomplete="off" required disabled={this.state.disabled} />
							</div>
						</div>
						<button className="btn btn-lg btn-danger btn-block" type="submit" disabled={this.state.disabled}>Entrar</button>
					</form>
				</div>

			</div>
		);
	}
}

export default Login;
